import React from 'react';
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';
ReactGA.pageview('/blog/mlh');

function MLH() {
  return (
    <div>
      <h2 className="mt-5">The Art of Managing a Team (from an 18 y/o)</h2>

      <img
        className="img-center mt-4 img-fluid img-fluid"
        src="https://miro.medium.com/max/700/0*aSk6pXQiRJ-EnMh8"
        alt="mlh logo"
        title=""
      />

      <h3 className="mt-4 font-weight-bold">A quick recap ...</h3>

      <p className="lead">
        I’ve spent the last 12 weeks participating in the{' '}
        <a
          href="https://fellowship.mlh.io/"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          MLH Fellowship
        </a>
        , contributing to open-source projects like Babel, SheetJS, and Docsify
        alongside 10 other student developers. I mainly contributed to{' '}
        <a
          href="https://github.com/babel/babel"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          Babel Core
        </a>{' '}
        (the compiler package that 7M+ people use every day) and{' '}
        <a
          href="https://github.com/sheetjs/sheetjs"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          SheetJS
        </a>{' '}
        (Excel/Word-like functionalities in JavaScript).
      </p>

      <p className="lead">
        I spent the first 2 months mainly working on low-level concepts like
        Abstract Syntax Tree (AST) bugs for Babel, and binaries/scripting for{' '}
        <a
          href="https://github.com/sheetjs/js-word"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          js-word
        </a>{' '}
        — a greenfield project in SheetJS. After familiarizing myself with the
        codebase and submitting several Pull Requests (PRs) to both projects
        (some noticeable ones{' '}
        <a
          href="https://github.com/babel/babel/pull/11703"
          target="_blank"
          rel="noreferrer noopener"
          className="underline"
        >
          here
        </a>{' '}
        and{' '}
        <a
          href="https://github.com/SheetJS/sheetjs/pull/2039"
          target="_blank"
          rel="noreferrer noopener"
          className="underline"
        >
          here
        </a>
        ), I wanted exposure leading to a more technical project.
      </p>

      <p className="lead">
        Specifically, I wanted to understand what it’s like to coordinate with
        others and manage group responsibilities. The reason for this is because
        getting to experience both the technical and management sides as a
        developer allowed me to better understand the problem and the product
        that I’m trying to solve and deliver. It made me think more about the
        code I wrote, the architecture of the platform, and the User Experience
        that I wanted to deliver (more throughout the blog).
      </p>

      <p className="lead">
        And, it was around this time when our group transitioned to working on{' '}
        <a
          href="https://babelsandbox.com/"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          Babel Sandbox
        </a>
        , a greenfield project from Henry Zhu (a maintainer at Babel). Babel
        Sandbox is an updated version to the{' '}
        <a
          href="https://babeljs.io/repl"
          target="_blank"
          rel="noreferrer noopener"
          className="underline"
        >
          Babel REPL
        </a>
        , with the main intention of making it easier to understand/contribute
        to Babel. We did this by combining features from AST Explorer, the Babel
        Handbook, and the Babel REPL into an IDE-like interface that you can{' '}
        <span className="font-weight-bold">
          easily share and clone just like a Git repository.
        </span>
      </p>

      <img
        className="img-center mt-4 img-fluid"
        src="https://miro.medium.com/max/700/1*U58NXEBmqEfkLoofUrR2sQ.gif"
        width="75%"
        alt="demo"
        title=""
      />

      <h3 className="mt-4 font-weight-bold">Creating design specs</h3>

      <p className="lead">
        At the start of the project, my main responsibility was drafting design
        specs for the main features our MVP would have. A design spec is a
        comprehensive document that contains all the important details in
        addressing the problem. The elements that I found important to address
        in a short amount of time were:
      </p>

      <ul>
        <li className="lead">
          The problem and how it started (this becomes the "mission statement")
        </li>
        <li className="lead">
          A general walkthrough of the problem &amp; steps to solve
        </li>
        <li className="lead">Any edge cases to consider</li>
      </ul>

      <p className="lead">
        It provides a general and consistent framework for describing problems
        and solutions. This is helpful in open source as it minimizes the
        barrier of entry for future contributors. It also provides a complete
        walkthrough of the author's thought process and the motivation behind
        it.
      </p>

      <img
        className="img-center mt-4 img-fluid pb-4"
        src="https://cdn-images-1.medium.com/max/800/1*Ef44DgMjJv8lmEUM7ee18g.png"
        alt="PR"
        title=""
      />

      <p className="lead">
        Having an initial draft of the design spec gets the entire group on the
        same page, which also makes it easier to isolate parts of the design
        spec into individual sections (often referred to as tickets).
      </p>

      <p className="lead">
        The tickets can offer more depth about the specific implementation,
        potential mockups, more discussions, etc. But, I learned that it's{' '}
        <span className="font-weight-bold">
          important that the tickets capture the scope of the problem
        </span>{' '}
        (nothing more) and the steps involved to avoid developers stepping on
        each other's toes and having duplicate work.
      </p>

      <h3 className="mt-4 font-weight-bold">Managing and assigning tickets</h3>

      <p className="lead">
        Having created the design specs and tickets, the next important part was
        to assign and manage the progress of the tickets. Since the fellowship
        is aimed at fellows learning new skills and developing their portfolio,
        assigning initial tickets was done based on interest.
      </p>

      <p className="lead">
        However, the new tickets that came up over the 4 weeks were mostly given
        based on similar work from before. Since we had daily standups, it was
        easy to be on the same page with everyone else and understand the scope
        of their work. Having that in mind made assigning tickets and asking for
        additional code reviewers more intuitive.
      </p>

      <p className="lead">
        It also made managing the weekly milestones more manageable because it
        allowed me to plan and assign the tasks accordingly. With four weeks, we
        had to limit the number of features that our MVP would include and make
        sure that the scope of the tickets is feasible.
      </p>

      <p className="lead">
        Each week was categorized with "themes" and common objectives we wanted
        to tackle that week. A glance at the milestones that we had was:
      </p>

      <ul>
        <li className="lead">1 - Get features created</li>
        <li className="lead">2 - Get features implemented</li>
        <li className="lead">3 - User Interface (UI) / Deploy</li>
        <li className="lead">4 - Bug patches / QA / low priority</li>
      </ul>

      <p className="lead">
        The first week was a challenging and rigorous week since it was a lot of
        design and implementation. The biggest roadblock was logistics and
        clarifying the details. Especially with open-source software, it's
        important to check if there is a license and the condition that you're
        allowed to use it. For example, having the AST Explorer was one of our
        core features, however, the open-source project that we relied on didn't
        have a license, in which we could legally use it.
      </p>

      <p className="lead">
        These kinds of logistic problems came up throughout the first two weeks,
        however, in the second week, we started to slowly piece together the
        stable features with minimal issues. Those features included the server,
        database, saving, and compressing data. That way, our group had a better
        picture of the project.
      </p>

      <p className="lead">
        As we headed into the final two milestones, most of the
        implementation-heavy work has been submitted, reviewed, merged, and the
        large majority of the tickets had become smaller. In these final weeks,
        I directed the focus towards the front-end with the UI and getting it
        deployed to have beta testers.
      </p>

      <p className="lead">
        I learned these past two weeks that it's important to emphasize having
        flexibility and lee-way in a tight schedule. That way, the work output
        will be consistent. It may seem prudent to set concrete deadlines, but
        with well-scoped, triaged, and documented tickets, it isn't necessary to
        enforce that.
      </p>

      <h3 className="mt-4 font-weight-bold">Code Reviews</h3>

      <p className="lead">What to look for in pull requests:</p>

      <ul>
        <li className="lead"> Working implementation</li>
        <li className="lead">Whether the code is documented</li>
        <li className="lead">Tests that check the code's functionality</li>
        <li className="lead">Consistency</li>
        <li className="lead">
          If there are any edge cases that we should consider that the PR didn't
          address (this would be a good time to file new issues to keep track
          of)
        </li>
      </ul>

      <p className="lead">
        Doing code reviews made me realize{' '}
        <span className="font-weight-bold">
          the importance of people's own preferences and approach to problems.
        </span>
        This became clear during discussions and mentioning the potential
        performance tradeoffs, how the User Experience (UX) should feel, etc. It
        made it apparent that sometimes the value in reviewing code is to{' '}
        <span className="font-weight-bold">
          not always give your take on certain topics,
        </span>{' '}
        but the opposite.
      </p>

      <p className="lead">
        By{' '}
        <span className="font-weight-bold">
          framing more of my comments as questions
        </span>
        , it made the dialogue more transparent. Often, I found that seeking to
        understand the approach through questions pushed the conversation
        further by being able to suggest new ideas and possible improvements
        towards the current solution.
      </p>

      <p className="lead">
        Code reviews also taught me to always keep in mind the future
        contributors that will be maintaining or referencing the code. Making
        code reviews more of a dialogue gave a clear understanding of the
        intentions from both sides, which is relevant in open-source software
        and in larger tech companies.
      </p>

      <p className="lead">
        For example, as I was reviewing one of the Pull Requests for the AST
        Visualization feature, I noticed a potential UX improvement that could
        be added, and the conversation went along the lines:
      </p>

      <img
        className="img-center mt-4 img-fluid"
        src="https://cdn-images-1.medium.com/max/800/1*wi5d0oGYc_20WJYzYkXq5Q.png"
        alt="Code Review"
        title=""
      />

      <h3 className="mt-4 font-weight-bold">Babel time travel</h3>

      <p className="lead">
        Aside from managing the project, I also contributed to the{' '}
        <a
          href="https://github.com/MLH-Fellowship/babel-sandbox/pull/78"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          Time Travel
        </a>{' '}
        feature as one of the main components in our MVP. This feature shows the
        step-by-step process in how Babel compiles next-generation JavaScript to
        ES5 JavaScript.
      </p>

      <p className="lead">
        I built this by capturing and appending each of the steps in the Babel
        transform operation (the function that compiles the input code) and
        iterating over the list to display the history to the user. It looks
        something like this:
      </p>

      <div
        style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }}
      >
        <iframe
          src="https://www.loom.com/embed/25170e18041a4d80be14f81c4b56faf4"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
          className="mt-4 pb-4"
        />
      </div>

      <h3 className="mt-4 font-weight-bold">
        More screenshots of Babel Sandbox
      </h3>

      <img
        className="img-center mt-4 img-fluid"
        src="https://miro.medium.com/max/700/1*XUkZgieyRyJQU-lhX8isgQ.png"
        alt="landing page"
        title=""
      />

      <img
        className="img-center mt-4 img-fluid"
        src="https://miro.medium.com/max/700/1*slRDVtRHz20BAMoPEKLU8Q.png"
        alt="AST visualization"
        title=""
      />

      <hr className="mt-4" />

      <p className="lead mt-3">
        Enjoyed reading this, or have comments? Feel free to message me on{' '}
        <a
          href="https://twitter.com/wlaw_"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          Twitter
        </a>{' '}
        .
      </p>

      <div>
        <Link to="/blog/investing">
          <p className="text-right">
            <span className="font-weight-bold">Next:</span>
            <br />
            Stuff I wish I knew before investing
        </p>
        </Link>
      </div>
    </div>
  );
}

export default MLH;
