// import { BrowserRouter as Router, Route } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import './styles/App.css';

import Header from './components/Header';
import Home from './components/Home';
import Blog from './components/Blog';
import Mlh from './blog/mlh/MLH';
import Invest from './blog/investing/Invest';
import YieldFarming from './blog/yieldFarming/yieldFarming'
import ScrollToTop from './components/ScrollToTop'

import ReactGA from 'react-ga';
ReactGA.initialize('G-CWTCFBFNJM');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <div className="container mt-5">
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="blog" element={<Blog />} />
            <Route path="mlh" element={<Mlh />} />
            <Route path="investing" element={<Invest />} />
            <Route path="yieldfarming" element={<YieldFarming />} />
          </Routes>
        </div>
      </div>
      <Analytics />
    </BrowserRouter>
  );
}

export default App;
