import React from 'react';
import { Link } from 'react-router-dom';

import Smartchain from './assets/smartchain.jpg'
import Bep20 from './assets/bep20.jpg'
import Liquidity from './assets/liquidity.jpg'
import Lp from './assets/lp.jpg'
import Addliquidity from './assets/addliquidity.jpg'
import Remove from './assets/remove.jpg'
import Farming from './assets/farming.jpg'

function yieldFarming() {
  return (
    <div>
      <h2 className="mt-5" id="a-beginners-guide-to-yield-farming">
        A Beginners Guide to Yield Farming
      </h2>
      <p className="lead mt-4">
        *Disclaimer: Never financial advice, always do your own research before
        doing these things.
      </p>
      <p className="lead">
        Crypto has been trending for the past six months, but it recently has
        attracted the eyes of institutions and the general public with
        Coinbase&#39;s recent IPO. I wanted to talk about Decentralized Finance
        (DeFi) and what it means to participate in yield farming. Since there
        aren&#39;t as many blogs and resources teaching these things, I wanted to
        provide my take and what I learned from my friends in the crypto community.
      </p>
      <p className="lead mb-3">
        <em>Note:</em> xBLZD and BNB will be the two coins that I&#39;ll be referring
        to throughout the blog. It&#39;s also important to understand that this yield
        farming happens on the Binance Smart Chain (BSC) network.
      </p>
      <h3 className="mt-4 mb-4 font-weight-bold" id="steps">Getting Started:</h3>
      <ol className="lead">
        <li className="mb-4">
          <span className="font-weight-bold">Buy the Binance Coin (BNB)</span>
          <br />
          The majority of the time, I use the Binance Exchange to buy BNB
          using USDC or USDT. If you don&#39;t have Binance but would like to sign up,
          you can use my code: {" "}
          <a
            href="https://www.binance.com/en/register?ref=FK7FNG6Y"
            target="_blank"
            rel="noreferrer noopener"
            className="highlight"
          >
            FK7FNG6Y
          </a>.
        </li>
        <li className="mb-4">
          <span className="font-weight-bold">Add BSC to Metamask network</span>
          <br />
          The next thing would be to connect your Metamask wallet to the BSC network.
          Binance has a {" "}
          <a
            href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
            target="_blank"
            rel="noreferrer noopener"
            className="highlight"
          >
            good guide
          </a>
          {" "} that I&#39;d recommend. For this, you would want to add the details under
          the &quot;Mainnet&quot; header.
        </li>
        <li className="mb-4">
          <p>
            <span className="font-weight-bold">Send BNB to Metamask address</span>
            <br />
            At the top of the right of your Metamask wallet, you&#39;d want to make
            sure the network says &quot;Smart Chain,&quot; the name that follows from
            the Binance guide, before sending BNB to your Metamask wallet.
          </p>

          <img
            className="img-center mt-4 mb-3 img-fluid"
            src={Smartchain}
            width="25%"
            alt="demo"
            title=""
          />

          <p>
            Send the BNB using the BEP20 network. As always, you can send a small
            amount as a test to make sure the transaction goes through properly.
          </p>

          <img
            className="img-center mt-4 img-fluid"
            src={Bep20}
            width="75%"
            alt="demo"
            title=""
          />

        </li>
        <li className="mb-4">
          <span className="font-weight-bold">Preparing to swap BNB for xBLZD</span>
          <br />
          Visit {" "}
          <a
            href="https://exchange.pancakeswap.finance/#/swap"
            target="_blank"
            rel="noreferrer noopener"
            className="highlight"
          >
            Pancakeswap
          </a>
          {" "} (PCS) and connect your Metamask wallet. Here you can swap the BNB for xBLZD. Note
          that there&#39;ll be times where the coin you want won&#39;t be listed on PCS.
          You can find the coin&#39;s contract on {" "}
          <a
            href="https://bscscan.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="highlight"
          >
            BSCScan
          </a>
          {" "} and paste the contract address to PCS.
        </li>
        <li className="mb-4">
          <span className="font-weight-bold">Adjust the slippage amount</span>
          <br />
          By default, PCS has a slippage amount of 0.8%, but I set mine at 5%.
          If you&#39;re familiar with trading stocks, slippage similarly refers to
          the bid/ask spread. Sometimes when transactions failed, it could be due
          to a low slippage amount. In that case, increasing the slippage could work.
          Generally, I found for new coins, the slippage should be around 10%.
        </li>
        <li className="mb-4">
          <p>
            <span className="font-weight-bold">Set your ratios!</span>
            <br />
            This next step is important because it&#39;ll be calculating how much
            of the coin you&#39;ll be buying and how much BNB you should hold.
            For example, if I have $100 in BNB that I&#39;m willing to use for farming,
            I shouldn&#39;t exchange the entire $100 for xBLZD because I need to save
            some BNB for liquidity.
          </p>
          <p>
            When yield farming, you need to maintain a liquidity ratio of 1:1 for the
            Liquidity Pool (LP). Meaning, if I decide to swap $45 of BNB for xBLZD, I
            would also need to have $45 of BNB left for the LP. Furthermore, I need to
            save some BNB to cover gas fees. In this example, I&#39;d leave the
            remaining $10 of BNB for fees.
          </p>
          <p>
            With that in mind, I would swap $45 of my BNB for xBLZD and keep the other
            $55 for LP and covering fees.
          </p>
        </li>
        <li className="mb-4">
          <p>
            <span className="font-weight-bold">Add liquidity</span>
            <br />
            After I swap and have my $45 of xBLZD, it&#39;s time to start farming!
            Head over to the {" "}
            <a
              href="https://exchange.pancakeswap.finance/#/pool"
              target="_blank"
              rel="noreferrer noopener"
              className="highlight"
            >
              Liquidity section
            </a>
            {" "} and add your $45 of BNB. PCS will automatically add $45 of xBLZD as well.
            Adding to the LP means that you stake both xBLZD and BNB, and so the sum of
            xBLZD and BNB would be represented in CAKE-LP tokens.
          </p>

          <img
            className="img-center mt-4 img-fluid"
            src={Liquidity}
            width="50%"
            alt="demo"
            title=""
          />
        </li>
        <li className="mb-4">
          <span className="font-weight-bold">Add supply</span>
          <br />
          Assuming the process in Step 7 went accordingly, there should be a
          &quot;Supply&quot; button, which would allow you to contribute to the LP.
        </li>
        <li className="mb-4">
          <p>
            <span className="font-weight-bold">Go to the farm site and then stake your LP tokens</span>
            <br />
            Next, head over to the site that you want to participate in yield farming.
            For me, at the time, I was using {" "}
            <a
              href="https://www.blizzard.money/farms"
              target="_blank"
              rel="noreferrer noopener"
              className="highlight"
            >
              Blizzard
            </a>
            .{" "}If you trust the site, connect your wallet and find the pool you want to
            participate in. For me, that was &quot;xBLZD-BNB LP&quot;. Here I was able to
            see:
          </p>

          <img
            className="img-center mt-4 mb-3 img-fluid"
            src={Lp}
            width="100%"
            alt="demo"
            title=""
          />
        </li>
        <li className="mb-4">
          <p>
            <span className="font-weight-bold">What do I do with my rewards?</span>
            <br />
            Rewards are paid daily. You can check the daily percentage beside the annual
            rate. When you earn rewards, you could just claim the xBLZD. Or, you can
            compound the amount by:
          </p>
          <ul className="lead">
            <li className="mb-3">
              Selling half of the rewards (i.e., 50% of the &quot;total xBLZD earned&quot;)
            </li>
            <li className="mb-3">
              Stake the other half (i.e., the remaining 50% of the &quot;total xBLZD
              earned&quot;)
            </li>
          </ul>
          <p>
            However, you have to remember from Step 6 about maintaining a liquidity ratio
            of 1:1 and whether the remaining BNB you have left can match the ratio. If you
            have enough and you want to add more liquidity, you can go back to the PCS
            Liquidity Section and follow the same procedure from Step 7:
          </p>
          <img
            className="img-center mt-4 img-fluid"
            src={Addliquidity}
            width="50%"
            alt="demo"
            title=""
          />
        </li>
        <li className="mb-4">
          <p>
            <span className="font-weight-bold">What happens after?</span>
            <br />
            When you&#39;re done with farming, you can withdraw your LP tokens
            from the farm site. Now go to PCS and remove it from the liquidity:
          </p>
          <img
            className="img-center mt-4 mb-3 img-fluid"
            src={Remove}
            width="50%"
            alt="demo"
            title=""
          />
          <p>
            When you do this, you will get back both the BNB and xBLZD to your
            Metamask wallet. If you no longer wish to hold xBLZD, you can swap
            it back to BNB.
          </p>
        </li>
      </ol>
      <p className="lead">
        And, that&#39;s it! That concludes the entire process of starting your
        crypto yield farm. This was my first time, so if there&#39;s anything
        incorrect or I&#39;m missing a step, feel free to reach out and correct me!
        Happy farming!
      </p>

      <img
        className="img-center mt-4 mb-3 img-fluid"
        src={Farming}
        width="50%"
        alt="demo"
        title=""
      />

      <hr className="mt-4" />

      <p className="lead mt-3">
        Enjoyed reading this, or have comments? Feel free to message me on{' '}
        <a
          href="https://twitter.com/wlaw_"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          Twitter
        </a>{' '}
        .
      </p>

      <div className="col-6 prev" style={{ paddingLeft: 0 }}>
        <Link to="/blog/investing">
          <span className="font-weight-bold">Previous:</span>
          <br />
          Stuff I wish I knew before investing
        </Link>
      </div>
    </div>
  )
}

export default yieldFarming;