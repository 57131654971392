import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-8">
          <h2 className="titles">
            <Link to="/">William Law</Link>
          </h2>
          {/*
          <div className='mt-3'>
            <p className="font-weight-normal">
              <span className='mr-4'>
                <a href="https://twitter.com/wlaw_" rel="noreferrer noopener">
                  Twitter
                </a>
              </span>
              <span className='mr-4'>
                <Link to="/blog">Writing</Link>
              </span>
              <span className='mr-4'>
                <a href="https://github.com/wlawt" rel="noreferrer noopener">
                  Github
                </a>
              </span>
              <span className='mr-4'>
                <a href="https://dribbble.com/wlawt" rel="noreferrer noopener">
                  Design
                </a>
              </span>
              <span className=''>
                <a href="https://www.linkedin.com/in/william-t-law/" rel="noreferrer noopener">
                  LinkedIn
                </a>
              </span>
            </p>
          </div>
          */}
        </div>
        <div className="col-6 col-md-4 right-heading">
          <p className="lead mr-5 right-title">
            <a href="https://twitter.com/wlaw_" rel="noreferrer noopener">
              Twitter
            </a>
          </p>
          <p className="lead right-title">
            <Link to="/blog">Blog</Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default Header;
