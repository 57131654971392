import React from 'react';
import { Link } from 'react-router-dom';

function Invest() {
  return (
    <div>
      <h2 className="mt-5" id="stuff-i-wish-i-knew-before-investing">
        Stuff I wish I knew before investing
      </h2>
      <p className="lead mt-4">
        *Disclaimer: never financial advice, this is just a reflection of all
        the things that I&#39;ve learnt from trading and investing.
      </p>
      <p className="lead">
        The past three months of actively day and swing trading have taught me a
        lot about understanding markets. I never understood how others would
        come to conclusions about the market sentiment and how a stock would
        move. I wanted to write this to summarize the important things that I
        learned and want to remember - like a note to look back.
      </p>
      <h3 className="mt-4 font-weight-bold" id="market-indicators">
        Market indicators
      </h3>
      <p className="lead">
        At the time of writing this (Feb. 28, 2021), these are the levels that I
        generally watch.
      </p>
      <p className="lead">
        I found that market indicators gave a sense of direction, if not a
        sentiment of the market behavior at the time of taking on positions.
        This has given me some clarity for opening long positions and can be
        good for swing trading.
      </p>
      <ul className="lead">
        <li>
          Futures
          <ul>
            <li>US30 - Dow Jones futures</li>
            <li>US500 - S&amp;P500 futures</li>
            <li>USTECH100 - Nasdaq futures</li>
            <li>US2000 - Small cap futures</li>
          </ul>
        </li>
        <li>
          $VIX - volatility indicator
          <ul>
            <li>
              The levels that I look at:
              <ul>
                <li>Above 20 → warning</li>
                <li>22.5 → caution</li>
                <li>25 → not good</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          $VXX - fear/uncertainty indicator
          <ul>
            <li>
              The levels that I look at:
              <ul>
                <li>Below 16 → good for bulls</li>
                <li>Above 17 → not good</li>
              </ul>
            </li>
            <li>Note: this is an inverse relationship with SPY.</li>
          </ul>
        </li>
        <li>
          US 10 year treasury bond ($US10YR)
          <ul>
            <li>
              The levels that I look at:
              <ul>
                <li>Under 1.4% → good for equities</li>
                <li>1.6% → as support</li>
                <li>1.9% → not good</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Crypto
          <ul>
            <li>$ETH → performance of not only ETH but also altcoins</li>
            <li>$BTC → generally indicates market sentiment/movement</li>
            <li>
              $DXY
              <ul>
                <li>
                  The levels that I look at: 88.5 - 90.8 to be safe for crypto
                </li>
              </ul>
            </li>
            <li>
              $BTC.D
              <ul>
                <li>
                  For altcoins
                  <ul>
                    <li>
                      Broadly speaking, you want the BTC dominance to fall
                    </li>
                  </ul>
                </li>
                <li>
                  For BTC → you want BTC dominance to pick up for BTC to ramp
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p className="lead">
        In addition to those, I generally look at the following tickers on a
        day-to-day basis:
      </p>
      <ul className="lead">
        <li>$SPY levels (S&amp;P500 index)</li>
        <li>$QQQ levels (big tech index, think FAANG)</li>
        <li>$IWM levels (Russell 2000 index)</li>
        <li>$AAPL/$TSLA → market movers for QQQ/SPY</li>
      </ul>

      <h3 className="mt-4 font-weight-bold" id="trading-indicators">
        Trading indicators
      </h3>
      <p className="lead">
        Mostly for day/swing trading. I also look at this when I want to see a
        good entry for long positions.
      </p>
      <ul className="lead">
        <li>
          Exponential Moving Averages (EMAs) - 20/50/200 day EMAs → look for
          these as supports
        </li>
        <li>Volume Weighted Average Price (VWAP)</li>
        <li>Volume → goal is to want strong volume + price action</li>
        <li>
          Volume Profile Visible Range (VPVR) → look for levels of liquidity for
          a visible range
        </li>
        <li>
          Relative Strength Index (RSI) → 30 and under generally oversold, above
          70 is being overbought, 30-70 good range
        </li>
        <li>Fib retracements</li>
        <li>Order flow - Cheddar Flow, Flow Algo, etc.</li>
      </ul>
      <h3 className="mt-4 font-weight-bold" id="portfolio-diversification">
        Portfolio diversification
      </h3>
      <p className="lead">
        The recent market pullback, rising treasury rates, and money rotation
        from tech to value stocks have made me rethink my portfolio allocation.
        Although I haven&#39;t changed my thesis in investing towards
        tech/innovation, I&#39;m looking for a rebalance for other sectors that
        will possibly act as a hedge to my portfolio. In other words, I&#39;m
        trying to build a more reasonable risk-to-reward ratio (r/r).

        Holdings in order of weight:
      </p>
      <ul className="lead">
        <li>Broader market indices (tracking NA and Asia markets)</li>
        <li>Tech (includes big tech, fintech, biotech)</li>
        <li>Crypto (alts, eth)</li>
        <li>Cash</li>
        <li>Energy (clean energy, utilities)</li>
      </ul>
      <p className="lead">
        At first, I didn&#39;t put much thought. Most of the dividing was done
        as I went and just how much I was comfortable with. However, I don&#39;t
        think it&#39;s too far off from allocations that I&#39;ve researched and
        the consideration of my risk tolerance. Going forward, I look to
        rebalance my portfolio and adding more weight on clean energy and
        broader market indices. What I&#39;m aiming for is (in terms of weight):
      </p>
      <ul className="lead">
        <li>Broader market indices</li>
        <li>Tech</li>
        <li>Energy</li>
        <li>Crypto</li>
        <li>Cash</li>
      </ul>
      <p className="lead">
        At times of uncertainty I also sit cash in my trading accounts for
        options &amp; crypto trading.
      </p>
      <h3 className="mt-4 font-weight-bold" id="risk-management">
        Risk management
      </h3>
      <p className="lead">
        In the beginning, I completely ignored risk management but quickly
        realized how important it is to manage my r/r. Cutting losses too early
        from emotional decisions or taking profit too late are repeated mistakes
        that I&#39;ve made in the past months due to greed.
      </p>
      <p className="lead">
        For trading equities and options, I set a ~30% Stop Loss (SL). This
        varies from person-to-person, but it has been working well for me. Since
        volatility can rapidly change the price of premiums, I don&#39;t want to
        get stopped out on a fluctuation that I know will bounce back. On the
        other hand, if I&#39;m swinging crypto, I set tighter SL&#39;s around
        10-20%.
      </p>
      <p className="lead">
        In general, for my long-term portfolio that includes equities and
        crypto, I don&#39;t set stop losses as I plan to hold for many years,
        and that pullbacks and corrections will be small fluctuations along this
        time horizon.
      </p>
      <p className="lead">
        As much as knowing when to cut losses, knowing when to take profit is
        equally important. Having Price Targets (PT) gives me a sense of
        direction and what resistance levels to expect. As the stock price meets
        my target, I found it a good idea to size out of a position by taking a
        certain percentage of profits at different levels. If a stock can go
        higher, I would have a handful of &quot;runners&quot; if it ever breaks
        new highs.
      </p>
      <p className="lead">
        Another rule I&#39;ve learned is that if the unrealized P/L on the trade
        is 30%+, move the SL up to the principal amount. In the chance that you
        get greedy and the price of the stock/security moves below your cost
        average, you&#39;ll break even on the trade.
      </p>
      <p className="lead">
        If a stock breaks new all-time highs, theoretically, there is
        &quot;unlimited upside&quot;. To find &quot;how high&quot; the stock
        could go, I found using fib extensions to be helpful. The addition of PT
        raises made by institutions confirms my thesis. To overtrading, revenge trading,
        or getting too greedy, I set my benchmark to the performance of
        the S&amp;P500, which is around 10% for its long-term average.
      </p>
      <h3 className="mt-4 font-weight-bold" id="other-things-that-i-ve-learned">
        Other things that I&#39;ve learned
      </h3>
      <ul className="lead">
        <li>
          Tickers have a different personalities, trade the ones you vibe with
        </li>
        <li>
          If you&#39;re contemplating about taking profit, chances are you
          should take profit.
        </li>
        <li>Don&#39;t be full bearish. Don&#39;t be full bullish.</li>
        <li>Trade level by level.</li>
        <li>Take profits by sizing out in respective amounts (%&#39;s).</li>
        <li>
          If you think a stock go higher, leave some positions as runners.
        </li>
        <li>
          Don&#39;t go all into a position at once. Do a starter. When you see
          confirmation, size in larger.
        </li>
        <li>
          Trading options on a small account: try to aim to have 2 contracts so that
          you can always take 1 for profit, have 1 as a runner.
        </li>
        <li>
          Cash is a position in a time of uncertainty, fear, and
          volatility.
        </li>
        <li>Profit is profit.</li>
        <li>Never let a green trade go red.</li>
      </ul>

      <p className="lead mt-4">
        As always, if there are improvements or incorrect information, let me
        know!
      </p>

      <hr className="mt-4" />

      <p className="lead mt-3">
        Enjoyed reading this, or have comments? Feel free to message me on{' '}
        <a
          href="https://twitter.com/wlaw_"
          target="_blank"
          rel="noreferrer noopener"
          className="highlight"
        >
          Twitter
        </a>{' '}
        .
      </p>


      <div className="col-6 prev" style={{ paddingLeft: 0 }}>
        <Link to="/blog/mlh">
          <span className="font-weight-bold">Previous:</span>
          <br />
          The Art of Managing a Team
        </Link>
      </div>

      <div className="next">
        <Link to="/blog/yield-farming">
          <p className="text-right">
            <span className="font-weight-bold">Next:</span>
            <br />
            A Beginners Guide to Yield Farming
        </p>
        </Link>
      </div>
    </div>
  );
}

export default Invest;
