import React, { Fragment } from 'react';

import previous from '../data/previous.json';
import projects from '../data/project.json';

function Home() {
  return (
    <Fragment>
      <div className="now mt-4">
        <h4 className="titles">About</h4>
        <p className="lead mt-2">
          Hi there! I am interested in crypto and improving blockchain infrastructure.
          I currently work at {" "}
          <span className="underline">
            <a href="https://avalabs.org/" rel="noreferrer noopener">
              Ava Labs
            </a>
          </span> on the Platform team building the {" "}
          <span className="underline">
            <a href="https://github.com/ava-labs/hypersdk" rel="noreferrer noopener">
              HyperSDK
            </a>
          </span>.

          Previously, I was at {" "}
          <span className="underline">
            <a href="https://aptoslabs.com/" rel="noreferrer noopener">
              Aptos Labs
            </a>
          </span> and {" "}
          <span className="underline">
            <a href="https://foundrydigital.com/" rel="noreferrer noopener">
              Foundry
            </a>
          </span>, a Digital Currency Group company.

          Aside from work, I enjoy lifting and playing poker.
        </p>
        <p className="lead mt-2">
          I'm also a fourth-year student at the University of Waterloo studying Computer
          Science with a focus on distributed systems.
        </p>
      </div>

      <div className="previous mt-5">
        <h4 className="titles">Summary</h4>
        <p className="lead mt-2">
          A brief overview of my resume.
        </p>
        {previous['company'].map((company) => (
          <p className="mt-4" key={company.name}>
            <span className="subtitles">
              <mark className="highlight">
                <a
                  href={`${company.link}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {company.name}
                </a>
              </mark>
            </span>{' '}
            — {company.description}
          </p>
        ))}
      </div>

      <div className="project mt-5">
        <h4 className="titles">Projects</h4>
        <p className="lead mt-2">
          A collection of all technical and non-technical projects that I’ve
          worked on. For a more complete list, check out my{' '}
          <span className="underline">
            <a href="https://github.com/wlawt">Github</a>
          </span>
          .
        </p>

        {projects['project'].map((project) => (
          <p className="mt-4" key={project.name}>
            <span className="subtitles">
              <mark className="highlight">
                <a
                  href={`${project.link}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {project.name}
                </a>
              </mark>
            </span>{' '}
            — {project.description}
          </p>
        ))}
      </div>

      <div className="coursework mt-5">
        <h4 className="titles">Education</h4>
        <p className="mt-2">
          <span className="lead">Technical coursework</span>
          <ul className="mt-2">
            <li>Database Systems (CS348)</li>
            <li>Computer Networks (CS456)</li>
            <li>Operating Systems (CS350)</li>
            <li>Compilers (CS241)</li>
            <li>Algorithms (CS341)</li>
            <li>Data Structures (CS240)</li>
            <li>Object-Oriented Software Development (CS246)</li>
            <li>Intro to Functional Programming (CS135)</li>
            <li>Intro to Combinatorics and Graph Theory (MATH239)</li>
            <li>Probability (STAT230)</li>
            <li>Statistics (STAT231)</li>
          </ul>
        </p>
        <p className="mt-2">
          <span className="lead">Other notable coursework</span>
          <ul className="mt-2">
            <li>Entrepreneurial Strategy (BET320)</li>
            <li>Customer Experience Design (BET350)</li>
            <li>Marketing Foundations for Early-stage Ventures (BET400)</li>
          </ul>
        </p>
        <p className="mt-2">
          <span className="lead">Research &amp; Technical Interests</span>
          <ul className="mt-2">
            <li>Large-scale cloud infrastructure</li>
            <li>Databases</li>
            <li>Operating Systems (scheduling, concurrency)</li>
            <li>User Experience</li>
          </ul>
        </p>
        <p className="mt-2">
          <span className="lead">Awards</span>
          <ul className="mt-2">
            <li>President's Scholarship of Distinction</li>
            <li>President's International Experience Award</li>
          </ul>
        </p>
      </div>

      <div className="contact mt-5">
        <h4 className="titles">Contact</h4>
        <p className="lead mt-2">
          I'm always open to new opportunities, collaborations, and meeting new
          people. Feel free to email me at williamlaw.wtl at gmail.com. I can
          also be found on{' '}
          <span className="underline">
            <a href="https://www.linkedin.com/in/william-t-law/">LinkedIn</a>
          </span>
          .
        </p>
      </div>
    </Fragment>
  );
}

export default Home;
