import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  return (
    <Fragment>
      <div className="blog mt-5">
        <h4 className="titles mb-3">Writings</h4>

        <p className="lead">
          Additionally, I have other articles available on my {" "}
          <span className="underline">
            <a href="https://wlawt.medium.com/" rel="noreferrer noopener">
              Medium
            </a>
          </span>.
        </p>

        <div className="blog-post">
          <span className="blog-title">
            <h5 className="font-weight-bold">
              <span className="blog-underline">
                <Link to="/yieldfarming">
                  A Beginners Guide to Yield Farming
                </Link>
              </span>
            </h5>
          </span>
          <span className="blog-title pl-2">
            — How to get started with crypto yield farming.
          </span>
          <p className="text-muted pt-2">April 15, 2021 | 7 mins</p>
        </div>

        <div className="blog-post">
          <span className="blog-title">
            <h5 className="font-weight-bold">
              <span className="blog-underline">
                <Link to="/investing">
                  Lessons I've learned from investing
                </Link>
              </span>
            </h5>
          </span>
          <span className="blog-title pl-2">
            — A future note for myself about investing and trading.
          </span>
          <p className="text-muted pt-2">March 14, 2021 | 5 mins</p>
        </div>

        <div className="blog-post">
          <span className="blog-title">
            <h5 className="font-weight-bold">
              <span className="blog-underline">
                <Link to="/mlh">The Art of Managing a Team</Link>
              </span>
            </h5>
          </span>
          <span className="blog-title pl-2">
            — Recap of my MLH Fellowship Experience as the first batch.
          </span>
          <p className="text-muted pt-2">August 21, 2020 | 7 mins</p>
        </div>
      </div>
    </Fragment>
  );
}

export default Blog;
